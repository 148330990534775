import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import CartierCategoryBtns from '../../components/preowned/CartierCategoryBtns'

// markup
const CartierRonde = () => {
  const data = useStaticQuery(
    graphql`
      query queryCartierRonde {
        products: allStrapiProduct(
          filter: { brand: { eq: "Cartier" }, model: { regex: "/Ronde/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Cartier Ronde Watches for Sale'}
      canonical={'/fine-watches/cartier/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Cartier Ronde Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/cartier/filter/model/ronde/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-cartier-ronde-header.png"
                alt="Pre-Owned Certified Used Cartier Ronde Header"
                aria-label="Used Cartier Ronde Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED CARTIER RONDE</h1>
            <h2>
              CARTIER RONDE: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED CARTIER WATCHES
            </h2>
            <h3>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED CARTIER RONDE WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              The history of Cartier starts in 1847 in Paris, France, where the luxury watch and
              jewelry brand was founded. Despite the many other iconic watches created by the brand,
              the uniqueness of the Cartier de Ronde watch comes from the fact that it was created
              by the famous Louis Cartier himself. At first, the watch was produced in small
              production rates. Now, everyone recognizes the Cartier Ronde watches as luxury
              timepieces made for true lovers of the classic image. Beauty in its simplicity and
              outstanding quality are describing the Cartier de Ronde watch quite well. Roman
              numerals, blue sword-shaped hands, and round envelope are the main elements that
              characterize this model. Are you interested in one of the Cartier Ronde watches for
              your collection? Want to buy pre-owned Cartier Ronde, sell pre-owned Cartier Ronde,
              trade pre-owned Cartier Ronde, or repair pre-owned Cartier Ronde watch? Have a look at
              our vast inventory of Cartier Ronde pre-owned watch, Cartier Ronde estate watch,
              Cartier Ronde vintage watch, Cartier Ronde antique watch, Cartier Ronde heirloom
              watch, and Cartier Ronde used watch. Buy Preowned Cartier Ronde Watch for the best
              price at Gray &amp; Sons, known as the top luxury watch seller in the U.S. Gray &amp;
              Sons is now offering a huge selection of fine Swiss watches, pre-owned and estate
              Cartier watches and has a large, in-stock inventory of Rolex watches for exceptional
              prices. Looking for a certified pre-owned Cartier watch? At Gray &amp; Sons,
              you will always find a top selection of used Cartier watches, including pre-owned,
              estate, vintage Ronde watches. You can buy used Cartier Ronde, sell used Cartier
              Ronde, trade used Cartier Ronde, or repair used Cartier Ronde at Gray &amp; Sons. We
              offer certified pre-owned watches.
              <br />
              <br />
              Popular Ronde de Cartier and Ronde Solo de Cartier Watch Models
              <ul>
                <li>Cartier Ronde Ref. W6701009 Automatic in Rose Gold</li>
                <li>Cartier Ronde Ref. W6700155 Quartz in Steel</li>
                <li>Cartier Ronde Ref. 3801 Automatic in Rose Gold</li>
                <li>Cartier Ronde Ref. W6701011 Automatic in Steel</li>
                <li>Cartier Ronde Ref. W6700455 Quartz in Yellow Gold</li>
              </ul>
              <br />
              <br />
              At Gray &amp; Sons, you can buy pre-owned Cartier Ronde, sell pre-owned Cartier Ronde,
              trade pre-owned Cartier Ronde, or repair pre-owned Cartier Ronde. A great Cartier
              Ronde for sale from our inventory the Cartier Ronde Louis Ref. WR007004. This watch is
              made out of white gold on a Black Leather strap with a 18k White Gold Deploy buckle.
              This Cartier watch has a 42 x 42 mm case with a Round caseback and Silver Guilloche
              Pave dial. Circa: 2010s. It is Gray and Sons Certified Pre-Owned and comes backed by
              our 12-month warranty.
              <br />
              <br />
              <ul>
                <li>Cartier Ronde Ref. W6701009 Automatic in Rose Gold</li>
                <li>Cartier Ronde Ref. W6700155 Quartz in Steel</li>
                <li>Cartier Ronde Ref. 3801 Automatic in Rose Gold</li>
                <li>Cartier Ronde Ref. W6701011 Automatic in Steel</li>
                <li>Cartier Ronde Ref. W6700455 Quartz in Yellow Gold</li>
              </ul>
              <br />
              <br />
              <b>A Great Cartier Ronde for sale from our inventory:</b>
              <br />
              Cartier Ronde Louis Ref. WR007004
              <br />
              This watch is made out of white gold on a Black Leather strap with a 18k White Gold
              Deploy buckle. This Cartier watch has a 42 x 42 mm case with a Round caseback and
              Silver Guilloche Pave dial. Circa: 2010s. It is Gray and Sons Certified Pre-Owned and
              comes backed by our 12-month warranty. Do you want to buy pre-owned Cartier Ronde,
              sell pre-owned Cartier Ronde, trade pre-owned Cartier Ronde, or repair pre-owned
              Cartier Ronde? You’re in the right place! Gray &amp; Sons is a top place to buy used
              Cartier Ronde, sell used Cartier Ronde, trade used Cartier Ronde, or repair used
              Cartier Ronde.
              <br />
              <br />
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED CARTIER WATCHES
              <br />
              <br />
              Our exclusive in-house warranty applies to all certified pre-owned Cartier
              watches, including Cartier Ronde pre-owned watch, Cartier Ronde estate watch, Cartier
              Ronde vintage watch, Cartier Ronde antique watch, Cartier Ronde heirloom watch, and
              Cartier Ronde used watch. For modern Rolex watches, we provide a two-year warranty.
              Gray &amp; Sons only offers watches that have been recently serviced, detailed,
              freshly lubricated, and timing adjusted. At Gray &amp; Sons, you’ll also find other
              iconic Cartier wristwatches, such as Cartier Ronde pre-owned watch, Cartier Ronde
              estate watch, Cartier Ronde vintage watch, Cartier Ronde antique watch, Cartier Ronde
              heirloom watch, and Cartier Ronde used watch, Cartier Tank, Cartier Pasha, Cartier
              Santos, and Cartier Ballon Bleu, or pre-owned Patek Philippe, Audemars Piguet, and
              Rolex. Make the first step, check out our{' '}
              <a href="/fine-watches/cartier/view-all-cartier-watches/">
                Cartier watch inventory here
              </a>
              and contact our representative for further assistance on{' '}
              <a href="/">www.grayandsons.com</a>. The leading independent, unauthorized Cartier
              watch dealers allowing for price flexibility and inventory control. Chat now with our
              specialists Rich and Vika via this link <a href="/chat-with-viktoria">CHAT NOW </a> to
              make the best deal with our decision-makers. If you’d like to meet in person with our
              highly qualified in-house watchmakers, just visit Gray &amp; Sons’ Showroom in
              Surfside, Miami. Feel free to ask any questions about the buying process, prices or
              details on our pre-owned certified luxury watches. Would you like to buy
              used Cartier Ronde, sell used Cartier Ronde, trade used Cartier Ronde, or repair used
              Cartier Ronde? Gray &amp; Sons is the #1 place recommended by our clients to buy
              pre-owned Cartier Ronde, sell pre-owned Cartier Ronde, trade pre-owned Cartier Ronde,
              or repair pre-owned Cartier Ronde watch.
              <br />
              <br />
              Do you need to repair your pre-owned/used Cartier watch? Gray &amp; Sons is a
              full-service watch repair shop offering a Cartier watch battery replacement for
              certain models along with many other services. If you don’t want to service your
              pre-owned watch, but you’re wondering where can I sell my used watch or where can I
              sell my jewelry… At Gray &amp; Sons, you can buy used Cartier Ronde, sell used Cartier
              Ronde, trade used Cartier Ronde, or repair used Cartier Ronde. Stay with us and sell
              your watch or sell your jewelry for cash today. Rated by the clients as the #1 watch
              buyer, Sell Us Your Jewelry offers the highest prices in the shortest time for luxury
              jewelry watches and is the best choice when you want to buy pre-owned Cartier Ronde,
              sell pre-owned Cartier Ronde, trade pre-owned Cartier Ronde, or repair pre-owned
              Cartier Ronde. What’s my watch worth? Get the evaluation from Sell Us Your Jewelry. As
              the Gray &amp; Sons’ buying division, Sell Us Your Jewelry has become the leading
              watch buyer and the top-rated place to sell your watch Miami and sell your jewelry
              Miami. Visit
              <a href="https://sellusyourjewelry.com"> www.sellusyourjewelry.com</a>
              , the online jewelry buyer and online watch buyer, and sell your lux watch. Make the
              best choice and buy used Cartier Ronde, sell used Cartier Ronde, trade used Cartier
              Ronde, or repair used Cartier Ronde at Gray &amp; Sons.
              <br />
              <br />
              Gray &amp; Sons is a professional luxury watch and jewelry store located in Surfside,
              Miami, just across from Bal Harbour Shops. Open six days a week with real watch and
              jewelry experts on-site. Our customers rate us as the #1 Miami used watch buyer and
              seller, assisting sellers and buyers of pre-owned Cartier Ronde watches in Miami,
              Miami Beach, Coral Gables, Coconut Grove, South Beach, Bal Harbour, Surfside, Sunny
              Isles, Aventura, Brickell, Pinecrest, Fort Lauderdale, and many more areas or over 42
              years.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/cartier/">
                <button>SHOP CARTIER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK CARTIER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <CartierCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default CartierRonde
